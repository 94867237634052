import cloneDeep from 'lodash.clonedeep'

export default {
  state: {
    list: [],
    presort: ['GB', 'CH', 'DE', 'DK', 'LI', 'LU', 'NL', 'NO', 'SE']
  },
  mutations: {
    setCountries(state, payload) {
      state.list = payload
    }
  },
  actions: {
    setCountriesList({commit}, payload) {
      let countries = payload.sort(function (a, b) {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })

      commit('setCountries', countries)
    }
  },
  getters: {
    listSorted: state => {
      let prs = cloneDeep(state.presort).reverse()
      return cloneDeep(state.list).sort((a, b) => {
        let rs1 = prs.indexOf(a.id)
        let rs2 = prs.indexOf(b.id)

        return rs2 - rs1
      })
    }
  }
}
