import Vue from 'vue'
import VueMeta from 'vue-meta'

import App from './App.vue'
import router from './router'

import sdk from '@/components/registration.sdk'

import VueTheMask from 'vue-the-mask'
import vSelect from 'vue-select'
import AsyncComputed from 'vue-async-computed'
import store from './store'
import sentry from '@/components/sentry'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(AsyncComputed)
Vue.use(VueTheMask)
Vue.use(sdk)
Vue.use(sentry)
Vue.use(require('vue-moment'));

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', {class: 'vs__icon-indicator-remove'}),
  },
  OpenIndicator: {
    render: createElement => createElement('span', {class: 'vs__icon-indicator-open'}),
  },
});

Vue.component('v-select', vSelect)

const init = () => {
  window.Vue = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

store.dispatch('initApp')
  .then(() => init())
  .catch(() => init())
