import uniqBy from 'lodash/uniqBy'

export default {
  state: {
    sicCodes: [],
    page: 1,
    totalPages: null
  },

  mutations: {

    'sicCodes.set' (state, payload) {
      state.sicCodes = payload
    },

    'sicCodes.push' (state, payload) {
      if (Array.isArray(payload) && payload.length > 0) {
        state.sicCodes = uniqBy([...state.sicCodes, ...payload], 'id')
      }
    },

    setTotalPages(state, payload) {
      state.totalPages = payload
    },

    'sicCodes.page' (state, payload) {
      state.page = parseInt(payload)
    },

    'sicCodes.addPage' (state) {
      state.page = state.page + 1
    }
  },

  actions: {

    getSicCodes (state, payload) {
      return this._vm.$sdk.registration.getSicCodesList(payload).then(({ headers, data }) => {
        const totalPages = parseInt(headers['x-pagination-page-count'])
        state.commit('setTotalPages', totalPages)
        state.commit('sicCodes.set', data)
        state.commit('sicCodes.addPage')
      })
    },

    'sicCodes.more' (state, payload) {
      return this._vm.$sdk.registration.getSicCodesList(payload).then(response => {
        state.commit('sicCodes.push', response.data)
        state.commit('sicCodes.addPage')
      })
    },

    'sicCodes.reset' (state) {
      state.commit('sicCodes.set', [])
      state.commit('sicCodes.page', 1)
    }
  }
}
