<template>
  <img :src="settings.registration_logo" :alt="settings.brand_name" class="settings-logo">
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Logo',
  computed: {
    ...mapState({
      settings: state => state.init.settings
    })
  }
}
</script>

<style lang="less">
.settings-logo {
  max-width: 150px;
}
</style>
