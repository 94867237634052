import Vue from 'vue'
import Vuex from 'vuex'

import init from '@/store/modules/init'
import codes from "@/store/modules/codes";
import registration from '@/store/modules/registration'
import countries from "@/store/modules/countries";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    init,
    codes,
    registration,
    countries
  },
  strict: process.env.NODE_ENV !== 'production'
})
