<template>
  <router-view v-if="available" />
  <technical-break v-else />
</template>

<script>
import {mapState} from "vuex";
import TechnicalBreak from "@/views/layouts/TechnicalBreak";

export default {
  name: 'App',
  components: {
    TechnicalBreak
  },
  computed: {
    ...mapState({
      settings: state => state.init.settings,
      available: state => state.init.available
    })
  },
  metaInfo () {
    let meta = {
      link: [],
      script: []
    }

    if (this.settings.brand_name) {
      meta.title = 'Welcome to ' + this.settings.brand_name + ' | Registration'
    }

    if (this.settings.favicon_url) {
      meta.link.push({
        rel: 'icon',
        href: this.settings.favicon_url
      })
    }

    if (this.settings.js_url) {
      meta.script.push({
        src: this.settings.js_url
      })
    }

    if (this.settings.registration_css_url) {
      meta.link.push({
        rel: 'stylesheet',
        href: this.settings.registration_css_url
      })
    }

    return meta
  }
}
</script>

<style lang="less" src="./assets/css/main.less"></style>
